.sign__social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  & span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: calc(33% - 10px);
    border-radius: 16px;
    color: #fff;

    cursor: pointer;
    transition: 0.5s;

    & svg {
      height: auto;
      fill: #fff;
      transition: 0.5s;
    }
    &.fb {
      background-color: #3b5999;
      & svg {
        width: 10px;
      }
    }
    &.gl {
      background-color: #df4a32;
      & svg {
        width: 18px;
      }
    }
    &.tw {
      background-color: #1da1f2;
      & svg {
        width: 18px;
      }
    }
    &:hover {
      background-color: #fff;
      &.fb svg {
        fill: #3b5999;
      }
      &.gl svg {
        fill: #df4a32;
      }
      &.tw svg {
        fill: #1da1f2;
      }
    }
  }
}

// link hover
.sign__text {
  & .link {
    position: relative;
    color: #2f80ed;
    cursor: pointer;

    &:hover {
      color: #2f80ed;
      text-decoration: underline;
    }
  }
}

// checkmark logo
.sign__group--checkbox {
  & input:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  & input:not(:checked) + label:after,
  & input:checked + label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    text-align: center;
    background: url("../../images/svg/checkmark.svg") no-repeat center/12px auto;
    transition: 0.5s;
    border-radius: 8px;
  }
}

.demo__sign__btn {
  background-color: #ffffff54 !important;
  color: #fff !important;
  width: 150px !important;
  height: 35px !important;
  &:hover {
    background-color: #ffffff !important;
    color: #151f30 !important;
  }
}
